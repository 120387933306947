<template>
  <el-form
      class="login-form"
      status-icon
      :rules="loginRules"
      ref="loginForm"
      :model="loginForm"
      label-width="0">
    <el-form-item prop="username">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          v-model="loginForm.username"
          auto-complete="off"
          placeholder="请输入账号">
        <i
            slot="prefix"
            class="icon-yonghu"></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          :type="passwordType"
          v-model="loginForm.password"
          auto-complete="off"
          placeholder="密码">
        <i
            class="el-icon-view el-input__icon"
            slot="suffix"
            @click="showPassword"></i>
        <i
            slot="prefix"
            class="icon-mima"></i>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button
          type="primary"
          size="small"
          @click.native.prevent="handleLogin"
          class="login-submit">登录
      </el-button>
    </el-form-item>
    <el-dialog
        title="请选择所属机构"
        :visible.sync="userDialog"
        width="30%"
        center>
          <el-radio-group v-model="resource" >
            <div v-for="(item,index) in userCenter" :key="index" style="margin-bottom: 10px">
              <el-radio size="medium" :label="index" border>{{item.name}}</el-radio>
            </div>
          </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="userDialog = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { chouseCenter } from '@/api/login'
export default {
  name: 'UserLogin',
  data () {
    return {
      userDialog: false,
      userCenter: [],
      resource: 0,
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      },
      passwordType: 'password'
    }
  },
  methods: {
    showPassword () {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    onSubmit () {
      const id = this.userCenter[this.resource].id
      chouseCenter(id).then(() => {
        this.$router.push({ path: '/' })
      })
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('LoginByUsername', this.loginForm).then((res) => {
            if (res.data.user.multiCenterFlag) {
              this.userDialog = true
              const obj = res.data.user.userCenters
              const arr = []
              for (const item in obj) {
                arr.push({
                  name: obj[item],
                  id: item
                })
              }
              this.userCenter = arr
            } else {
              this.$router.push({ path: '/' })
            }
          }).catch(err => {
            this.$message.error(err.msg || '登录失败')
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
